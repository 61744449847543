<template>
    <div class="home">
        <Header />
        <div class="root">
                <div class="top">
                    <div class="top_content">
                        <div class="info" v-show="infoShow">
                            <div class="icon"><img src="../assets/image/personal.png" alt=""></div>
                            <div class="user_id">
                                <input v-model="user_id" type="text" placeholder="请先输入您要储值的游戏ID">
                            </div>
                            <div class="query" >
                                <button  @click="queryUser" type="submit">查詢</button>
                            </div>
                        </div>
                        <div class="user_info" v-show="!infoShow">
                            <div class="user_info_icon"><img :src="face_md5" alt=""></div>
                            <div class="user_info_detail">
                                <p class="user_info_name">{{ nickName }}</p>
                                <p class="user_info_ID" >ID：{{userId}}</p>
                            </div>
                            <div class="user_info_update" @click="updateUser">
                                <button>修改</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main">
                    <div class="main_title">
                        <div class="main_title_item"><span class="">選擇您想儲值的開心豆數量</span></div>
                        <div class="main_title_addr"> 
                            <span class="">您的貨幣地區</span>  
                            <select name="currency" id="" @change="changeCurrency" v-model="currency">
                                <option :value="item.currency" v-for="(item, index) in currencyList" :key="index">{{item.name}}</option>
                            </select>
                        </div>  
                    </div>
                    <div class="good">
                        <div>
                            <div class="good_item" :disabled="isSubmitting" :class="[ item.mall_id == activeDiv ? 'active' : '' ]"  @click="selectGood(item.mall_id)" v-for="(item, index) in goodsList" :key="index">
                                <div class="discount"><span class="">多赠{{ item.increment_percentage }}%再打{{item.discount}}折</span>  </div>
                                <div class="bean"><div class="bean_img"><img :src="item.thumb_icon"></div></div>
                                <div class="bean_num_gifts"><span>{{ item.number }}</span></div>
                                <div class="bean_num"><span><del>{{ item.original_number }}</del></span></div>
                                <div class="money_num_gifts"><span>{{ item.price }}{{currency}}</span></div>
                                <div class="money_num"><span><del>{{ item.original_price }}{{currency}}</del></span></div>
                                <div v-show="item.mall_id == activeDiv ? true : false" class="check"><img src="../assets/image/check.png" alt=""></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pay">
                    <div class="pay_title">請選擇支付方式</div>
                    <div class="pay_item">
                        <div class="pay_item_mycard" v-if="mycardStatus" :class="[activePayment == 'mycard'? 'active' : '' ]" @click="selectPayment('mycard')">
                            <div class="mycard_content">
                                <div class="mycard_icon"><img src="../assets/image/my_card.png" alt=""></div>
                                <div class="mycard_title">My Card支付</div>
                                <div v-if="is_hot == 'mycard'? true : false" class="isHot"><img src="../assets/image/pay_hot.png" alt=""></div>
                                <div v-if="activePayment == 'mycard'? true : false" class="check"><img src="../assets/image/check.png" alt=""></div>
                            </div>
                        </div>
                        <div class="pay_item_razer" v-if="razerpayStatus" :class="[activePayment == 'razerpay'? 'active' : '' ]" @click="selectPayment('razerpay')">
                            <div class="razer_content">
                                <div class="razer_icon"><img src="../assets/image/razer.png" alt=""></div>
                                <div class="razer_title">
                                    <div class="razer_title_english">Razer Gold</div>
                                    <div class="razer_title_cheinese">雷蛇支付</div>
                                    <div v-if="is_hot == 'razerpay'? true : false" class="isHot"><img src="../assets/image/pay_hot.png" alt=""></div>
                                    <div v-if="activePayment == 'razerpay'? true : false" class="check"><img src="../assets/image/check.png" alt=""></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pay_confirm">
                    <div class="pay_button" @click="createOrder"><button>去支付</button></div>
                </div>
        </div>
        <FootNav  :activeIndex="active"/>
    </div>
  </template>
  
  <script>

import Header from "../components/Header.vue";
import FootNav from "../components/FootNav.vue";
export default {
    components: {
      Header,
      FootNav
    },
    data() {
        return {
            active:4,
            infoShow:true,
            userDetail:{},
            nickName:'',
            face_md5:"",
            userId:'',
            razerpayStatus:true,
            mycardStatus:true,
            currency: sessionStorage.getItem('currencyDetail') ? JSON.parse(localStorage.getItem('currencyDetail')) : "TWD",
            goodsList:[],
            currencyList:[],
            payTypeList:[],
            clickedElement:'',
            activeDiv:0,
            activePayment:'mycard',
            is_hot:"mycard",
            mall_id:'',
            isSubmitting : false,
        };
    },
    created(){
        // if(sessionStorage.getItem('currencyDetail')){
        //     this.currency = JSON.parse(localStorage.getItem('currencyDetail'))
        //     console.log( JSON.parse(localStorage.getItem('currencyDetail')))
        // }
        this.getCurrencyList();
        this.getGoodsList();
        
    },
    computed:{

    },
    mounted() {
        if(localStorage.getItem('userInfo')){
            this.nickName = JSON.parse(localStorage.getItem('userInfo')).nickname
            this.userId = JSON.parse(localStorage.getItem('userInfo')).user_id
            this.face_md5 = JSON.parse(localStorage.getItem('userInfo')).face_md5
            this.infoShow = false
        }
        this.setCurrency()
    },
    methods:{
        queryUser(){
            if(this.user_id != undefined){
                this.service.post('Home/checkUserInfo',{user_id:this.user_id})
                .then((res) => {
                    if(res.data.status == 0){
                        this.$toast({
                            message: res.data.msg,
                            position: 'top',
                        });
                    }
                    if(res.data.status != 0){
                        if(res.data.data.face_md5 == ""){
                            res.data.data.face_md5 = require('@/assets/image/default.png')
                        }
                        localStorage.setItem('userInfo',JSON.stringify(res.data.data)) 
                        this.nickName = JSON.parse(localStorage.getItem('userInfo')).nickname
                        this.userId = JSON.parse(localStorage.getItem('userInfo')).user_id
                        this.face_md5 = JSON.parse(localStorage.getItem('userInfo')).face_md5
                        this.infoShow = false
                    }
                }) 
            }
        },
        updateUser(){
            this.infoShow = true
            localStorage.setItem('userInfo','')
        },
        getGoodsList(){
            this.currency = sessionStorage.getItem('currencyDetail') ? JSON.parse(localStorage.getItem('currencyDetail')) : "TWD"
            this.service.post('Home/getGoodsList',{currency:this.currency})
            .then((res) => {
                if(res.data.status != 0){
                    this.goodsList = res.data.data.list
                    this.activeDiv = res.data.data.list[0]['mall_id']
                    this.mall_id = res.data.data.list[0]['mall_id']
                    this.currency = res.data.data.currency
                    this.is_hot = res.data.data.is_hot
                    // sessionStorage.setItem('currencyDetail',JSON.stringify(res.data.data.currency)) 
                }
            }) 
        },
        getCurrencyList(){
            this.service.post('Home/getCurrencyList')
            .then((res) => {
                if(res.data.status != 0){
                    this.currencyList = res.data.data
                }
            }) 
        },
        changeCurrency(){
            this.service.post('Home/getGoodsList',{currency:this.currency})
            .then((res) => {
                if(res.data.status != 0){
                    this.goodsList = res.data.data.list
                    this.activeDiv = res.data.data.list[0]['mall_id']
                    this.currency = res.data.data.currency
                    this.is_hot = res.data.data.is_hot
                    // this.mycardStatus = res.data.data.mycard_status
                    // this.razerpayStatus = res.data.data.razerpay_status
                    // if(this.mycardStatus == false){
                    //     this.activePayment = 'razerpay'
                    // }
                    sessionStorage.setItem('currencyDetail',JSON.stringify(res.data.data.currency)) 
                }
            }) 
            
        },
        createOrder(){
            this.$dialog.confirm({
                message: '确实支付吗?'
            })
            .then(() => {
                this.service.post('WebPayment/createOrder',{
                    currency: this.currency,
                    payment_key: this.activePayment,
                    mall_id: this.mall_id,
                    user_id: this.userId
                })
                .then((res) => {
                    if(res.data.status != 0){
                        window.location.href = res.data.data.paymentUrl;
                    }else{
                        this.$toast({
                            message: res.data.msg,
                            position: 'top',
                        });
                    }
                }) 
            })
            .catch(() => {

            });
            
        },
        selectGood(index) {
            this.activeDiv = index
            this.mall_id = index
        },
        selectPayment(index){
            this.activePayment = index
        },
    },
    updated(){
        
    }
};
  
  </script>
  
<style>
.root{
    padding: 0px 10px;
    width: 100%;
    margin-bottom: 70px;
}
.top{
    height: 124px;
    padding: 50px 0px;
    width: 100%;
    
}
.top_content{
    width: 90%;
    height: 40px;
    line-height: 40px;
    margin: 0px auto;
}
.user_info::after{
    display: block;
    clear: both;
    content: '';
}
.user_info .user_info_icon {
    width: 13%;
    height: 40px;
    float: left;
    margin-right: 2%
    /* border-radius: 20px;
    border: 1px solid #ffffff; */
}
.user_info .user_info_icon img{
    height: 100%;
    width: 100%;
    border-radius: 100%;
    border: 1px solid #ffffff;
}
.user_info_detail{
    width: 65%;
    height: 40px;
    float: left;
}
.user_info_detail p{
    line-height: 20px;
    color: #FFFFFF;
}
.user_info .user_info_update{
    width: 20%;
    float: left;
}
.user_info .user_info_update button{
    display: inline-block;
}
.user_info .user_info_update button{
    background:linear-gradient(to bottom,#FFE13D,#FFAF3D);
    width: 100%;
    height: 40px;
    border-radius: 3px;
    color:#0D6945;
    border: none;
}
.info .icon {
    width: 13%;
    height: 32px;
    float: left;
}
.info .icon img{
    height: 32px;
}
.info .user_id{
    float: left;
    width: 70%;
    height: 32px;
}
.info input{
    height: 32px;
    line-height: 32px;
    width: 100%;
    float: left;
    border: none;
    border-radius: 3px;
}
.info .query{
    width: 15%;
    line-height: 32px;
    margin-left: 2%;
    float: left;
}
.info .query button{
    background:linear-gradient(to bottom,#98E05F,#0ECD9C);
    width: 50px;
    height: 32px;
    border-radius: 3px;
    color:#0D6945;
    border: none;
}
.main_title{
    width: 100%;
    line-height: 25px;
    margin-bottom: 13px;
    height: 25px;
    clear: none;
}
.main_title_item{
    float: left;
    width: 60%;
    
}
.main_title_addr{
    float: left;
    width: 40%;
    line-height: 25px;
}
.main_title_item span {
    font-size: 13px;
    text-align: right;
    color: #ffffff;
    line-height: 17px;
}
.main_title_addr span{
    width: 60%;
    float: left;
    font-size: 13px;
    height: 25px;
    color: #ffffff;
    line-height: 25px;
}
.main_title_addr select{
    width: 40%;
    height: 25px;
    float: right;
    font-size: 13px;
    border-radius: 3px;
    background:linear-gradient(to bottom,rgba(255, 129, 61, 1),rgba(202, 74, 23, 0.6));
    opacity: 0.6;
    border: 1px solid #FFC843;
    color:#ffffff;
}

.main_title_addr select option{
    background-color: rgba(0,0,0,0.9);
    color: #ffffff;
}
.good{
    margin-bottom: 20px;
}
.good::after{
    content: "";
    clear: both;
    display: block;
}
.good_item{
    height: 140px;
    width: 32%;
    margin-right: 2%;
    margin-bottom: 2%;
    border-radius: 5px;
    border: 1px solid #f4cabd;
    position: relative;
    float: left;
}
.good_item:nth-child(3n){
    margin-right: 0px;
}

.discount{
    font-size: 10px;
    color: #ffffff;
    width: 100%;
    height: 18px;
    clear: both;
    content: '';
}
.discount span{
    background-color: #FF5436;
    height: 18px;
    line-height: 18px;
    text-align: center;
    width: 80%;
    float: right;
    border-radius: 4px;
}
.bean{
    width: 100%;
    height: 40px;
    margin-top: 4px;
}
.bean .bean_img{
    margin: auto;
    width: 50px;
    height: 40px;
    text-align: center;
}
.bean .bean_img img{
    width: 50px;
    height: 40px;
    
}
.bean_num_gifts{
    text-align: center;
    font-size: 14px;
    color: #FFF9A9;
    line-height: 17px;
}
.bean_num_gifts{
    text-align: center;
    font-size: 14px;
    color: #FFF9A9;
    line-height: 17px;
}
.good_item .bean_num{
    text-align: center;
    font-size: 10px;
    line-height: 17px;
    color: #f4cabd;
    text-decoration: line-through;
    display: block; /* 确保元素是块级元素 */
    visibility: visible; /* 确保元素是可见的 */
    
}
.money_num_gifts{
    font-size: 19px;
    line-height: 21px;
    text-align: center;
    color: #FFED00;
    margin-top: 4px;
}
.good_item .money_num{
    text-align: center;
    font-size: 10px;
    line-height: 17px;
    color: #f4cabd;
    text-decoration: line-through;
}
.active{
    background-color: rgba(255, 246, 128, 0.3);
    border: 1px solid #FFED00;
}
.pay .pay_item .active{
    background-color: rgba(255, 246, 128, 0.3);
    border: 1px solid #FFED00!important;
}
.isHot{
    position: absolute;
    right: 0px;
    top: 0px;
    width: 32px;
    height: 32px;
}
.isHot img{
    width: 100%;
    height: 100%;
}
.check{
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 17px;
    height: 17px;
}
.check img{
    width: 100%;
    height: 100%;
}

.pay{
    width: 100%;
    height: 100%;
}
.pay_title{
    color: #ffffff;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 8px;
}
.pay_item{
    width: 100%;
    height: 56px;
}
.pay_item_mycard{
    width: 48%;
    height: 56px;
    margin-right: 4%;
    border: 1px solid #FFFFFF;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
    border-radius: 5px;
    float: left;
    position: relative;
}
.mycard_content{
    width: 90%;
    height: 37px;
    margin: 10px auto;
}
.mycard_icon{
    width: 30%;
    height: 100%;
    float: left;
}
.mycard_icon img{
    height: 37px;
    width: 37px;
}
.mycard_content .mycard_title{
    width: 70%;
    line-height: 37px;
    font-size: 11px;
    color: #FFFFFF;
    float: left;
}
.pay_item_razer{
    width: 48%;
    height: 56px;
    border: 1px solid #FFFFFF;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
    border-radius: 5px;
    float: left;
    position: relative;
}
.razer_content{
    width: 90%;
    height: 37px;
    margin: 10px auto;
    
}
.razer_icon{
    width: 55%;
    height: 100%;
    float: left;
    margin-right: 5%;
}
.razer_icon img{
    height: 100%;
    width: 100%;
}
.razer_title{
    width: 40%;
    float: left;
    height: 37px;
    font-size: 11px;
    color: #ffffff;
    
}
.razer_title .razer_title_english{
    line-height: 22px;
}
.razer_title .razer_title_cheinese{
    line-height: 15px;
}
.pay_confirm{
    width: 100%;
    padding: 0xp auto;
    margin-top: 16px;
}
.pay_button{
    margin: 0px auto;
    height: 42px;
    line-height: 42px;
    width: 60%;
}
.pay_button button{
    height: 42px;
    line-height: 42px;
    width: 100%;
    border-radius: 20px;
    border: none;
    color: #DC5823;
    font-size: 16px;
    font-weight: 500;
    background:linear-gradient(to bottom,#ffe346,#ffa43d);
    box-shadow: (inset, 2px 50 0px 50px #FFF193);
    font-weight: bolder;

}

</style>
  